import Head from './Head';
import PageBody, { ViewName } from 'views/PageBody';
import SettingsSection from './SettingsSection';
import useMount from 'hooks/useMount';
import Welcome from 'views/Welcome';
import { useEffect, useRef } from 'react';

export type Props = {
  authModalIsOpen: boolean;
  closeModal: () => void;
  isAnonymous: boolean;
  navigate: (x: { path: string; replace: boolean }) => void;
  openLoginModal: (x: { context: string }) => void;
  setHasHero: (hasHero: boolean) => void;
};

function Settings({
  authModalIsOpen,
  closeModal,
  isAnonymous,
  navigate,
  openLoginModal,
  setHasHero,
}: Props) {
  const authModalWasOpen = useRef(authModalIsOpen);

  useMount(() => {
    setHasHero(false);
    if (isAnonymous) {
      openLoginModal({ context: 'reg-gate' });
    }
  });

  useEffect(() => {
    if (authModalWasOpen.current && !authModalIsOpen && isAnonymous) {
      navigate({ path: '/', replace: true });
    }
    if (authModalIsOpen && !isAnonymous) {
      closeModal();
    }
    authModalWasOpen.current = authModalIsOpen;
  }, [authModalIsOpen]);

  return (
    <>
      <Head />
      {isAnonymous ? (
        <Welcome />
      ) : (
        <PageBody dataTest={ViewName.ProfileSettings}>
          <SettingsSection />
        </PageBody>
      )}
    </>
  );
}

export default Settings;
